<template>
  <div class="pr-2 pl-2">
    <div class="d-flex">
      <v-text-field
        label="Elemento de costo"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="costGroupElement.costItem"
      ></v-text-field>
      <v-text-field
        label="Porcentaje de App"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="costGroupElement.percentApp"
      ></v-text-field>
      <v-text-field
        label="Unidades"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="costGroupElement.units"
      ></v-text-field>
      <v-text-field
        label="Precio"
         
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="costGroupElement.price"
      ></v-text-field>

      <v-btn
        depressed
        color="accent white--text"
        class="mr-2"
        @click="saveNewCostGroupElement()"
      >
      <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="primary" class="mr-2" @click="reset()">
        <v-icon> mdi-restart</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";

export default {
  data() {
    return {
      costGroupElement: {
        costItem: "",
        percentApplication: "",
        units: "",
        price: "",
      },
    };
  },
  methods: {
    async saveNewCostGroupElement() {
      let payload = {
        storedGroupCostItemId: this.$route.params.id,
        directSliceCostItemId: this.costGroupElement.costItem,
        percentApplication: this.costGroupElement.percentApplication,
        unitsPerHectare: this.costGroupElement.units,
        price: this.costGroupElement.price,
      };
      let response = await postRequest(
        "/stored_cost_item_group_element",
        payload
      );
      if (!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else {
        this.$store.dispatch("fetchCostGroupElements");
        this.reset();
      }
    },
    reset() {
      this.costGroupElement = {
        costItem: "",
        percentApplication: "",
        units: "",
        price: "",
      };
    },
  },
};
</script>
