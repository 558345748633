<template>
  <v-container>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div>
          <h1 class="ml-2" style="font-weight: 200">Single Input Group</h1>
        </div>
        <div class="ml-2">
          <b class="secondary--text subtitle-1">New Group Element</b>
        </div>
        <new-cost-group-element />
        <div>
          <cost-group-element
            :row="element"
            v-for="(element, i) in this.costItemGroupElements"
            :key="i"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import NewCostGroupElement from "../components/CostGroup/NewCostGroupElement.vue";
import CostGroupElement from "../components/CostGroup/CostGroupElement.vue";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["costItemGroupElements"]),
  },
  mounted() {
    this.$store.dispatch("fetchCostGroupElements");
  },
  components: {
    NewCostGroupElement,
    CostGroupElement,
  },
};
</script>
