<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="editMode">
      <v-text-field
        label="Elemento de costo"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="newRow.directSliceCostItemId"
      ></v-text-field>
      <v-text-field
        label="Porcentaje de App"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.percentApplication"
      ></v-text-field>
      <v-text-field
        label="Unidades"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.unitsPerHectare"
      ></v-text-field>
      <v-text-field
        label="Precio"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.price"
      ></v-text-field>

      <v-btn
        depressed
        color="accent white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn
        depressed
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
    </div>

    <div class="d-flex" v-if="!editMode">
      <v-text-field
        label="Elemento de costo"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="row.directSliceCostItemId"
        readonly
      ></v-text-field>
      <v-text-field
        label="Porcentaje de App"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="row.percentApplication"
        readonly
      ></v-text-field>
      <v-text-field
        label="Unidades"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="row.unitsPerHectare"
        readonly
      ></v-text-field>
      <v-text-field
        label="Precio"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="row.price"
        readonly
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { putRequest, deleteRequest } from "../../managrx/serverCall";

export default {
  name: "CostGroupElement",
  data() {
    return {
      editMode: false,
      deleteMode: false,
      newRow: {},
    };
  },
  props: ["row"],
  methods: {
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      let obj = {
        storedGroupCostItemId: this.newRow.storedGroupCostItemId,
        directSliceCostItemId: this.newRow.directSliceCostItemId,
        percentApplication: this.newRow.percentApplication,
        unitsPerHectare: this.newRow.unitsPerHectare,
        price: this.newRow.price,
      };
      let response = await putRequest(
        "/stored_cost_item_group_element/" + this.newRow.id,
        obj
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("fetchCostGroupElements");
      }
      this.editMode = false;
    },
    async confirmDeleteClick() {
      let response = await deleteRequest(
        "/stored_cost_item_group_element/" + this.row.id
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("fetchCostGroupElements");
      }
      this.backClick();
    },
    editClick() {
      this.newRow = { ...this.row };
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    resetNewRow() {
      this.newRow = {};
    },
  },
};
</script>
